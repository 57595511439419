@font-face {
  font-family: 'Zeyada';
  src: url('../../../public/Zeyada-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  width: 100%;
  flex-direction: row;

  padding: 1rem 5rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 5rem 5rem 0rem;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-left {
  color: #ffffff;
}
.app__header-left-hi-line {
  width: 20%;
  display: inline-block;
  hr {
    border-color: rgba(255, 255, 255, 0.4);
  }
}

.app__header-left-name {
  color: #59b1c0;
  font-family: 'Zeyada';
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
}

.app__header-left-webDeveloper {
  color: rgba(255, 255, 255, 0.4);
}
.app__header-left-text {
  color: rgba(255, 255, 255, 0.4);
  width: 71%;

  span {
    color: #fff;
  }

  a {
    text-decoration: none;
    color: #fff;
    position: relative;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: var(--secondary-color);
    }
  }

  a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: var(--secondary-color);
    transition: width 0.5s ease-in-out;
    transform-origin: left;
    transform: scaleX(0);
  }

  a:hover::before {
    width: 100%;
    transform: scaleX(1);
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  justify-content: flex-end;

  position: relative;

  img {
    width: 100%;
    border-radius: 100%;
  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}
